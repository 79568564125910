import { Suspense, lazy, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import Loader from "../components/Loader/Loader";

const TicketDesign1 = lazy(() => import("../pages/Tickets/Design1"));
const TicketDesign2 = lazy(() => import("../pages/Tickets/Design2"));
const TicketDesign3 = lazy(() => import("../pages/Tickets/Design3"));

const CheckoutDesign1 = lazy(() => import("../pages/checkout/Design1"));
const CheckoutDesign2 = lazy(() => import("../pages/checkout/Design2"));

export default function Router() {
  const location = useLocation();

  const scrollToTop = (url) => {
    if (!url.includes("#")) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    scrollToTop(window.location.href);
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Navigate to="/ticket1" />} />

        <Route
          path="/ticket1"
          element={
            <Suspense fallback={<Loader />}>
              <TicketDesign1 />
            </Suspense>
          }
        />

        <Route
          path="/ticket2"
          element={
            <Suspense fallback={<Loader />}>
              <TicketDesign2 />
            </Suspense>
          }
        />

        <Route
          path="/ticket3"
          element={
            <Suspense fallback={<Loader />}>
              <TicketDesign3 />
            </Suspense>
          }
        />

        <Route
          path="/checkout1"
          element={
            <Suspense fallback={<Loader />}>
              <CheckoutDesign1 />
            </Suspense>
          }
        />

        <Route
          path="/checkout2"
          element={
            <Suspense fallback={<Loader />}>
              <CheckoutDesign2 />
            </Suspense>
          }
        />

        <Route path="404" element={<>Not Found</>} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Routes>
    </>
  );
}
