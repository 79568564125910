import { configureStore } from "@reduxjs/toolkit";
// import thunkMiddleware from "redux-thunk";

const store = configureStore({
  reducer: {},
  // middleware: (getDefaultMiddleware) =>
  //   getDefaultMiddleware().concat(thunkMiddleware),
  // devTools: process.env.NODE_ENV === "production" ? false : true,
});

export default store;
